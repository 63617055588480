import { render, staticRenderFns } from "./PhaseSubmission.vue?vue&type=template&id=44d3e23f&scoped=true&lang=pug&"
import script from "./PhaseSubmission.vue?vue&type=script&lang=js&"
export * from "./PhaseSubmission.vue?vue&type=script&lang=js&"
import style0 from "./PhaseSubmission.vue?vue&type=style&index=0&id=44d3e23f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44d3e23f",
  null
  
)

export default component.exports
import QField from 'quasar/src/components/field/QField.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QField,QTabs,QTab,QIcon,QInput,QBtn});
