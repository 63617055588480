<template lang="pug">
div
  div(v-if="canSelectType")
    .text-body1.text-center {{ $t("how-do-you-want-to-submit") }}
    q-field(
      bottom-slots,
      :rules="[(val) => subtype != 'none' || $t('select-a-submission-type')]",
      borderless
    )
      .row.full-width.justify-center
        q-tabs.q-my-lg(
          v-model="subtype",
          active-bg-color="secondary",
          active-color="white"
        )
          q-tab.text-secondary(
            name="video",
            no-caps,
            :icon="matDownload",
            :label="$t('video')",
            style="border: 1px solid black"
          )
          .q-mx-lg or
          q-tab.text-secondary(
            name="text",
            no-caps,
            :icon="matEdit",
            :label="$t('text')"
          )

  .q-mt-xl(v-if="subtype === 'text'")
    .text-center.q-my-lg
      .text-h2 {{ $t(`phases.${phase}.vid1.title`) }}
      .text-body1.text-grey-9 {{ $t(`phases.${phase}.vid1.description`) }}
    FormInput(
      type="textarea",
      v-model="submission.formdata.textEntry",
      :name="$t('submission-text')",
      lazy-rules,
      bottom-slots,
      :hint="countWords",
      autogrow,
      :rules="[(val) => validateWordCount(val) || this.$t('more-text-is-required')]"
    )

  div(v-if="subtype === 'video' || !canSelectType")
    .text-center.text-caption.bg-grey-1.q-pa-xs.q-mb-xl.text-bold {{ $t("upload_again") }}
    div(v-for="(vid, n) in numberOfVideos")
      div(v-if="canUpload(vid - 1)")
        .text-center.q-my-xl
          //- q-icon(:name="matEmojiObjects", size="lg")
          .text-h2 
            |
            |
            |
            |
            |
            |
            |
            |
            |
            |
            |
            |
            |
            |
            |
            |
            |
            |
            |
            |
            |
            span(v-if="numberOfVideos > 1") {{ n + 1 }}.
            span {{ $t(`phases.${phase}.vid${vid}.title`) }}
          .text-body1.text-grey-9 {{ $t(`phases.${phase}.vid${vid}.description`) }}
        FormFileInput(
          :ref="`field${vid - 1}`",
          v-on:rejected="$emit('rejected')",
          v-model="files[vid - 1]"
        )

  //- div {{subtype}}

  //- Team
  div(v-if="!submission.id && !loading && hasTeam")
    .text-center.q-my-xl
      .q-pt-xl
        //- q-icon(:name="matGroups", size="lg")
        .text-h2 {{ $t("myteam") }}
        .text-body1 {{ $t("team_text") }}

    .row
      .col.q-py-sm.full-width
        .row.q-col-gutter-md.q-mb-md
          .col-12.col-md
            .relative-position
              q-input(
                v-model="newperson.name",
                ref="name",
                flat,
                outlined,
                :error="nameinvalid",
                :error-message="$t('requires_name')"
              )
              .floatinglabel
                .row
                  .col-auto
                    .bg-lilac.q-pa-xs.text-dark {{ $t("team_name") }}
          .col-12.col-md
            .relative-position
              q-input(
                v-model="newperson.email",
                outlined,
                ref="email",
                type="email",
                :error="emailinvalid",
                :error-message="$t('requires_email')"
              )
              .floatinglabel
                .row
                  .col-auto
                    .bg-lilac.q-pa-xs.text-dark {{ $t("team_email") }}
          .col-12.col-md
            .relative-position
              q-input(
                v-model="newperson.age",
                outlined,
                ref="age",
                type="number",
                :error="ageInvalid",
                :error-message="$t('requires_age')"
              )
              .floatinglabel
                .row
                  .col-auto
                    .bg-lilac.q-pa-xs.text-dark {{ $t("team_age") }}
          .col-md-auto.col-12.q-mt-sm.text-right
            .q-mt-xs
              q-btn.bg-accent(
                @click="addPerson()",
                flat,
                square,
                dense,
                :icon="matAdd"
              )
        .q-mb-lg(style="border-bottom: 2px dashed black")

        .row.q-col-gutter-md
          .col-md-4.col-12
            .bg-primary.text-white.q-pa-sm.q-px-md
              .text-body1 {{ user.displayName }}
              div {{ user.email }}

          .col-md-4.col-12(
            v-for="(person, index) in team",
            :key="person.email"
          )
            .bg-primary.text-white.q-pa-sm.q-px-md
              .row.items-center
                .col
                  .text-body1 {{ person.name }}
                  div {{ person.email }}
                .col-auto
                  q-btn.bg-white.q-pa-none(
                    square,
                    dense,
                    flat,
                    color="black",
                    @click="removePerson(index)"
                  )
                    q-icon(:name="matClear")
</template>

<script>
import FormInput from "@/components/FormInput";
import FormFileInput from "@/components/FormFileInput";
import { mapState } from "vuex";
import { find } from "lodash";

export default {
  name: "PhaseSubmission",
  components: {
    FormInput,
    FormFileInput,
  },
  data: () => {
    return {
      newperson: {
        name: "",
        email: "",
        age: "",
      },
      // subtypes: [{ label: "Text", value: "text" }],
      subtype: "none",
      team: [],
      nameinvalid: false,
      emailinvalid: false,
      ageInvalid: false,
    };
  },
  methods: {
    removePerson(index) {
      this.team.splice(index, 1);
    },
    addPerson() {
      if (!this.newperson.name) this.nameinvalid = true;
      else {
        this.nameinvalid = false;
        this.$refs.name.resetValidation();
      }
      if (!this.newperson.email) this.emailinvalid = true;
      else {
        this.emailinvalid = false;
        this.$refs.email.resetValidation();
      }
      if (!this.newperson.age || parseInt(this.newperson.age) < 10)
        this.ageInvalid = true;
      else {
        this.ageInvalid = false;
        this.$refs.age.resetValidation();
      }

      if (this.newperson.name && this.newperson.email && this.newperson.age) {
        this.team.push(this.newperson);
        this.newperson = { name: "", email: "", age: "" };
        this.$refs.email.resetValidation();
        this.$refs.name.resetValidation();
        this.$refs.age.resetValidation();
      }
    },
    canUpload(index) {
      if (!this.neededFiles) return true;
      else if (this.neededFiles[index]) return true;
      else return false;
    },
    validateWordCount() {
      // console.log(this.submission.formdata.textEntry.split(" ").length);
      if (!this.submission.formdata.textEntry) return false;
      else return this.submission.formdata.textEntry.split(" ").length > 200;
    },
  },
  watch: {
    subtype: {
      handler() {
        if (this.submission) this.submission.subtype = this.subtype;
      },
    },
    team: {
      deep: true,
      handler() {
        this.submission.team = this.team;
      },
    },
  },
  computed: {
    ...mapState(["user"]),
    canSelectType() {
      if (
        find(this.config.phases, { code: this.phase }).hasText &&
        !this.submission.id
      )
        return true;
      else return false;
    },
    countWords() {
      if (this.submission.formdata.textEntry) {
        return this.$t("wordcount", [
          this.submission.formdata.textEntry.split(" ").length,
        ]);
      } else return "";
    },
    hasTeam() {
      return find(this.config.phases, { code: this.phase }).team || false;
    },
    numberOfVideos() {
      return parseInt(
        find(this.config.phases, { code: this.phase }).noOfVideos
      );
    },
  },
  props: [
    "files",
    "submission",
    "languageList",
    "commsLanguages",
    "countryList",
    "neededFiles",
    "loading",
    "phase",
    "config",
  ],
};
</script>

<style lang="scss" scoped>
// @import "@/styles/quasar.variables.scss";
.fieldborder {
  border-color: #bdbdbd;
  border-width: 1px;
  border-style: solid;
}
</style>
