<template lang="pug">
div(style="position:relative")
  q-file.full-width(
    ref="formInput",
    v-model="inputVal",
    outlined,
    accept=".mp4, video/*",
    :disable="disable",
    lazy-rules,
    @rejected="$emit('rejected')",
    :rules="[(val) => !!val || $t('video_required')]"
  )
    //- template(v-slot:prepend)
    //-   q-icon(:name="matMovie", size="sm" color="primary")
    template(v-slot:append)
      q-btn(flat :icon="matUpload" dense square color="black" @click="getFile").bg-accent
  .floatinglabel
    .row
      .col-auto
        .bg-lilac.q-pa-xs.text-dark {{$t('select_video')}}
</template>

<script>
  export default {
    props: ["value", "icon", "name", "disable"],
    methods: {
      async validate() {
        return await this.$refs.formInput.validate();
      },
      getFile() {
        this.$refs.formInput.$el.click();
      },
    },
    computed: {
      fieldname() {
        return `${this.name}_title`;
      },
      verifiedname() {
        return `${this.name}_not_verified`;
      },
      inputVal: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit("input", val);
        },
      },
    },
  };
</script>
